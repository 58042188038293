

import React from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Logo from '../../logo.png';
import { Flex, Input, Space } from 'antd';
import { isMobile } from '../../utils';


function Bottom() {
  useRequest(async () => {

  });


  return (
    <Flex className={Styles['bottom']} vertical wrap>
      <Flex  align='center'  wrap={isMobile} vertical={isMobile} gap={10} justify='center'>
        <div>
          <h3 className={Styles['bottom-title']}>合作伙伴</h3>
          <p>上海瑞晟康护理有限公司</p>
          <p>安徽世祥病人陪护服务有限公司</p>
        </div>
        <div>
          <h3 className={Styles['bottom-title']}>友情链接</h3>
          <p>上海瑞晟康护理有限公司</p>
          <p>安徽世祥病人陪护服务有限公司</p>
        </div>

        <Flex align='center'>
          <h3 className={Styles['bottom-title']}>关注阜大嫂</h3>

        </Flex>
      </Flex>

      <Flex justify='space-around'>
        <p>备案号</p>
      </Flex>

    </Flex>
  );
}

export default Bottom;
