import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createHashRouter, createBrowserRouter, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Overview from './pages/Overview';
import Bottom from './components/Bottom';
import Info from './pages/Overview/Info';
import Qualifications from './pages/Overview/qualifications';
import Nurse from './pages/Major/Nurse';
import School from './pages/Elegance/School';
import Student from './pages/Elegance/Student';
import Contact from './pages/Contact';
import Search from './pages/Search';
import { isMobile } from './utils';

const routers = createHashRouter([
  {
    path: '/overview',
    children: [
      {
        path: '/overview/info',
        element: <Info />
      },
      {
        path: '/overview/qualifications',
        element: <Qualifications />
      },
    ]
  },
  {
    path: '/major',
    children: [
      {
        path: '/major/nurse',
        element: <Nurse />
      },
     
    ]
  },
  {
    path: '/elegance',
    children: [
      {
        path: '/elegance/school',
        element: <School />
      },
      {
        path: '/elegance/student',
        element: <Student />
      },
     
    ]
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/',
    element: <Home />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF5B00',
        },
      }}
    >
      <div className={isMobile ? 'page-mobile': 'page'}>
        <NavBar />
          <RouterProvider router={routers} />
        <Bottom />
      </div>
    </ConfigProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
