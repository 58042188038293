import React from 'react';

import { Button, Carousel, Flex } from 'antd';
import Styles from './index.module.css';
import Banner01 from '../../static/banner_01.png';
import ScInfoPng from '../../static/sc-info.png';
import { SearchOutlined, RightOutlined } from '@ant-design/icons';
import Card01 from '../../static/card_01.png';
import Card02 from '../../static/card_02.png';
import Card03 from '../../static/card_03.png';
import New01 from '../../static/news_01.png';
import New02 from '../../static/news_02.png';
import New03 from '../../static/news_03.png';
import New04 from '../../static/news_04.png';

import Ad01 from '../../static/ad_01.png';
import Ad02 from '../../static/ad_02.png';
import Ad03 from '../../static/ad_03.png';

import Contact from '../../static/contact.png';



import { useRequest } from 'ahooks';
import { isMobile } from '../../utils';


// 轮播
const Pictures = [
  {
    title: (
      <div>
        <p>未来，容你所想</p>
        <p>让每一个生命都收获感动</p>
      </div>
    ),
    url: Banner01
  }
]

// 专业
const Majors = [
  {
    title: '医疗护理员培训',
    url: Card01,
    pathname: '#/major/nurse'
  },
  {
    title: '养老护理员培训',
    url: Card02,
    disabled: true
  },
  {
    title: '育婴员培训',
    url: Card03,
    disabled: true
  }
]
// 新闻
const News = [
  {
    title: '阜大嫂职业培训学校首届医疗护理员圆满结业',
    desc: '在充满希望与期待的氛围中，阜大嫂职业培训学校于今日成功举办了首届医疗护理员结业典礼，标志着又一批仇秀学员在这里完成了他们的学业旅程，即将踏上人生新的征程。',
    time: '2024/11/10',
    url: New01
  },
  {
    title: '阜大嫂职业培训学校医疗护理员第二期即将开班',
    desc: '',
    time: '2024/11/10',
    url: New02
  },
  {
    title: '阜大嫂职业培训学校学习期间，学校提供免费午餐',
    desc: '',
    time: '2024/11/10',
    url: New03
  },
  {
    title: '阜大嫂职业培训学校第一届优秀毕业生',
    desc: '',
    time: '2024/11/10',
    url: New04
  }
]

// 优势
const Advantages = [
  {
    title: '荣誉资质',
    desc: '阜大嫂职业技能培训学校是颍泉区人社局定点培训机构',
    url: Ad01
  },
  {
    title: '师资力量',
    desc: '具备完善的教学能力，丰富的行业实践经验',
    url: Ad02
  },
  {
    title: '教学方法',
    desc: '多元化，理论和实践结合，模拟项目和实操演练',
    url: Ad03
  }
]

function Home() {

  return (
    <div className={Styles['home']}>
      <Carousel autoplay>
        {
          Pictures.map((pic, index) => {
            return (
              <div className={`${Styles['pic']} banner-pic`} key={index}>
                <img src={pic.url} alt="banner" />
                <div className={`${Styles['pic-title']} pic-title`}>{pic.title}</div>
              </div>
            )
          })
        }
      </Carousel>

      <Flex vertical={true} className={Styles['card']}>
        <h2>学校简介</h2>
        <Flex wrap={isMobile} vertical={isMobile} gap={10}>
          <img src={ScInfoPng} alt="info" className={Styles['img-cover']} />
          <Flex vertical className={Styles['basic-right']}>
            <h4>阜大嫂职业技能培训学校</h4>
            <span>
              阜大嫂职业技能培训学校阜大嫂职业技能培训学校是颍泉区人社局定点培训枧构。学校拥有完善的运营体系、一体化教学设备、配套的实操训练设施和医院本科、大专学历持有中级以上职称的专业老师团队授课。培训结束考试合格者颁发结业证书，就业意向者百分百推荐就业。
              <a href="#/overview/info">查看更多 &gt;</a>
            </span>
          </Flex>
        </Flex>
      </Flex>

      <div style={{ background: '#fafafd' }}>
        <Flex vertical={true} className={Styles['card']} >
          <Flex className={Styles['search-card-content']} vertical={true} align='center'>
            <h2>证书查询</h2>
            <div
              style={{ width: isMobile ? '100%' : 500 }}
              className={Styles['search-input']}
              onClick={() => {
                window.location.hash = '#/search';
              }}>
              <div>
                <SearchOutlined className={Styles['input-icon']} />
                <span className={Styles['input-placeholder']}>请输入证书编号</span>
              </div>
              <Button type='primary'>查询</Button>
            </div>
          </Flex>
        </Flex>
      </div>

      <div>

        <Flex className={Styles['card']} vertical={true} >
          <Flex>
            <h2>培训专业</h2>
            {/* <a href="/">查看更多 <RightOutlined style={{ fontSize: 10, fontWeight: 'blod' }} /></a> */}
          </Flex>

          <Flex vertical={isMobile} wrap={isMobile}>
            {
              Majors.map((major, index) => {
                return (
                  <div className={Styles['sc-card']} key={index}>
                    <img src={major.url} alt="" />
                    <h4>{major.title}</h4>
                    <span
                      className={!major?.disabled ? Styles['card-action'] : Styles['card-disabled-action']}
                      onClick={() => {
                        if (major.pathname) {
                          window.location.hash = major.pathname;
                        }

                      }}
                    >
                      查看详情
                    </span>
                  </div>
                )
              })
            }
          </Flex>
        </Flex>
      </div>


      <div style={{ background: '#eff5fe' }}>
        <Flex vertical={true} className={Styles['card']}>
          <Flex justify='space-around'>
            <h2>资讯中心</h2>
            {/* <a href="/">更多新闻 <RightOutlined style={{ fontSize: 10 }} /></a> */}
          </Flex>

          <Flex wrap={isMobile} vertical={isMobile} gap={10} >
            <Flex className={Styles['news-left']} vertical={true} flex={1}>
              <img src={News[0]?.url} alt="" className={Styles['img-cover']} />
              <span className={Styles['news-title']}>{News[0]?.title}</span>
              <span className={Styles['news-desc']}>{News[0]?.desc}</span>
              <Flex justify='space-between'>
                {/* <a href="/">查看全文 <RightOutlined style={{ fontSize: 10, fontWeight: 'blod' }} /></a> */}
                <span className={Styles['news-time']}>{News[0]?.time}</span>
              </Flex>
            </Flex>
            <Flex className={Styles['news-right']} flex={1} vertical={true}>
              {
                News.map((news, index) => {
                  if (index === 0) return null;
                  return (
                    <Flex vertical={false} style={{ marginBottom: 30 }} key={index}>
                      <img src={news.url} alt="" className={Styles['img-news']} />
                      <Flex vertical={true} style={{ marginLeft: 20 }}>
                        <span className={Styles['news-time']}>{news.time}</span>
                        <span className={Styles['news-title']}>{news.title}</span>
                        {/* <a href="/">查看全文 <RightOutlined style={{ fontSize: 10, fontWeight: 'blod' }} /></a> */}
                      </Flex>
                    </Flex>
                  )
                })
              }
            </Flex>
          </Flex>
        </Flex>
      </div>

      <Flex vertical={true} align='center' className={Styles['card']}>

        <h2>学校优势</h2>
        <Flex align='center' wrap={isMobile} vertical={isMobile} gap={10} justify='center'>
          {
            Advantages.map((ad, index) => {
              return (
                <Flex vertical align='center' key={index} justify='center' style={{ width: 300 }}>
                  <img src={ad.url} alt="" className={Styles['ad-img']} />
                  <span className={Styles['ad-title']}>{ad.title}</span>
                  <span className={Styles['ad-desc']}>{ad.desc}</span>
                  <a href="#/overview/qualifications">了解更多 <RightOutlined style={{ fontSize: 10, fontWeight: 'blod' }} /></a>
                </Flex>
              )
            })
          }
        </Flex>
      </Flex>


      <Flex className={Styles['card']} vertical wrap>
        <h2>联系我们</h2>
        <Flex style={{ width: '100%' }} wrap={isMobile} vertical={isMobile} gap={10}>
          <Flex vertical wrap>

            <h3 style={{ fontWeight: 'normal' }}>阜阳市阜大嫂职业技能培训有限公司</h3>
            <p>咨询电话：0558-2703311</p>
            <p>地址：安徽省阜阳市颍泉区中市街道颍州中路建银大厦电梯上6楼</p>
            <p>路线：市内乘1路、3路、4路、5路、9路、16路、22路、23路、25路公交车，至〖百货大楼站〗下车，建银大厦电梯上6楼</p>
          </Flex>
          <img src={Contact} alt="" className={Styles['img-cover']} />
        </Flex>
      </Flex>


    </div>
  );
}

export default Home;
