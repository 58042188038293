import React from 'react';
import { useRequest } from 'ahooks';
import Info01 from '../../../static/qua_01.png';
import Info02 from '../../../static/qua_02.png';

import { Flex, Input, Space, Breadcrumb, Menu } from 'antd';
import SubWrap from '../../../components/SubWrap';



function Qualifications() {

  return (
    <div>
      <SubWrap
        title={'学校概况'}

        menus={
          [
            {
              title: '学校简介',
              path: '#/overview/info'
            },
            {
              title: '荣誉资质',
              path: '#/overview/qualifications'
            }
          ]
        }
      >
        <>
          <Flex wrap gap="large">
            <img src={Info01} alt="" />
            <img src={Info02} alt="" />

          </Flex>
        </>
      </SubWrap>
    </div>
  );
}

export default Qualifications;
