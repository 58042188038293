import React, { useState } from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Student01 from '../../../static/student_01.png';
import Student02 from '../../../static/student_02.png';
import Student03 from '../../../static/student_03.png';

import Zmage from 'react-zmage';
import "react-zmage/lib/zmage.css"

import { Flex, Input, Space, Breadcrumb, Menu, Button, Carousel } from 'antd';
import SubWrap from '../../../components/SubWrap';

import {
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';



// 轮播
const Pictures = [
  {
    title: '第一期医疗护理员学员集体合影',
    pics: [
      {
        small: Student01,
        big: Student02
      },
      {
        small: Student02,
        big: Student02
      },
      {
        small: Student03,
        big: Student03
      },
      {
        small: Student01,
        big: Student02
      },
      {
        small: Student02,
        big: Student02
      },
      {
        small: Student03,
        big: Student03
      }
    ]
  },

  {
    title: '第二期医疗护理员学员集体合影',
    pics: [
      {
        small: Student01,
        big: Student02
      },
      {
        small: Student02,
        big: Student02
      },
      {
        small: Student03,
        big: Student03
      }
    ]
  },
]

function Detail(props: any) {
  const basicLength = 3;
  const [length, setLength] = React.useState(basicLength);
  const list = props.pics.slice(0, length);

  return (
    <Flex vertical align='center'>
      <Flex gap={'large'} wrap>
        {
          list?.map((picture: any, i: any) => {
            return (
              <img src={picture.small} alt="" key={i} onClick={()=> {
                Zmage.browsing({
                  src: picture.small
                })
              }}/>
            )
          })
        }
      </Flex>

      {
        length < props.pics.length &&
        <DownOutlined
          className={Styles['open']}
          title='展开更多'
          onClick={() => {
            setLength(props.pics.length)
          }} />

      }

      {
        length > basicLength &&
        <UpOutlined
          className={Styles['open']}
          title='收起'
          onClick={() => {
            setLength(basicLength)
          }} />
      }
    </Flex>
  )

}


function School() {

  useRequest(async () => {

  });

  return (
    <div>
      <SubWrap
        title={'学校风采'}
        hideContentTitle
        menus={
          [
            {
              title: '校园面貌',
              path: '#/elegance/school'
            },
            {
              title: '学员风采',
              path: '#/elegance/student'
            }

          ]
        }
      >
        <div className={Styles['student']}>

          {
            Pictures.map((pic, index) => {
              return (
                <Flex vertical style={{ borderBottom: '1px solid #ebebeb', paddingBottom: 30 }} key={index}>
                  <Flex align='around-space'>
                    <h3>{pic.title}</h3>
                  </Flex>

                  <Detail pics={pic.pics} />
                </Flex>

              )
            })
          }

        </div>
      </SubWrap>
    </div>
  );
}

export default School;
