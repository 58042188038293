import React, { useState } from 'react';
import Styles from './index.module.css';
import { useRequest } from 'ahooks';
import Nurse01 from '../../../static/nurse_01.png';
import Nurse02 from '../../../static/nurse_02.png';

import { Flex, Input, Space, Breadcrumb, Menu, Button } from 'antd';
import SubWrap from '../../../components/SubWrap';
import { isMobile } from '../../../utils';



function Nurse() {

  // 报名
  const [enroll, setEnroll] = useState(false);

  useRequest(async () => {

  });




  return (
    <div>
      <SubWrap
        title={'培训专业'}
        hideContentTitle
        menus={
          [
            {
              title: '医疗护理员',
              path: '#/major/nurse'
            },

          ]
        }
      >
        {
          !enroll ?
            <Flex gap={'large'} className={Styles['content']} align='center' vertical={isMobile} wrap={true}>
              <img src={Nurse01} alt="" style={{ borderRadius: 16, marginLeft: isMobile ? 0 : 20 }} height={isMobile ? 'inherit' : 260} width={isMobile ? '100%' : 'inherit'}/>
              <Flex vertical gap={'large'} className={Styles['right']} >
                <h3>医疗护理员</h3>
                <Flex vertical gap={'large'}>
                  <div>
                    培训内容：
                    <span>
                      医疗护理员职业方向的国家政策、职业道德和职业礼仪等。
                      <p>1. 基础护理知识</p>
                      <p>2. 骨科、神经内科等科室护理</p>
                      <p>3. 产科护理</p>
                      <p>4. 康复护理</p>
                      <p>5. 心理护理</p>
                      <p>6. 安全护理等</p>
                    </span>
                  </div>
                  <div>
                    培训学时：<span>医疗护理员国家标准培训学时120学时。</span>
                  </div>
                  <div>
                    培训对象：<span>16~36岁具有劳动能力的男女都可以学习。</span>
                  </div>
                  <div>
                    培训费用：<span>16~63岁均可学习。</span>
                  </div>
                  <Button type='primary' style={{ width: 100 }} onClick={() => setEnroll(true)}>我要报名</Button>
                </Flex>
              </Flex>
            </Flex> :
            <Flex gap={'large'} className={Styles['content']} vertical>
              <img src={Nurse02} alt="" width={1090} />
              <h3 style={{ textAlign: 'center' }}>医疗护理员招生简章</h3>
              <Flex vertical={isMobile} wrap={isMobile}>
                <Flex vertical className={Styles['card']} style={{ borderRight: isMobile ? 'none' : '1px solid #ebebeb' }}>
                  <div className={Styles['title']}><span>1</span>培训专业</div>
                  <div className={Styles['desc']}>医疗护理员</div>

                  <div className={Styles['title']}><span>2</span>培训内容</div>
                  <div className={Styles['desc']}>
                    医疗护理员职业方向的国家政策、职业道德和职业礼仪等。
                    <p>1. 基础护理知识</p>
                    <p>2. 骨科、神经内科等科室护理</p>
                    <p>3. 产科护理</p>
                    <p>4. 康复护理</p>
                    <p>5. 心理护理</p>
                    <p>6. 安全护理等</p>
                  </div>

                  <div className={Styles['title']}><span>3</span>培训学时</div>
                  <div className={Styles['desc']}>医疗护理员国家标准培训学时120学时</div>

                  <div className={Styles['title']}><span>4</span>培训对象</div>
                  <div className={Styles['desc']}>16~36岁具有劳动能力的男女都可以学习</div>

                  <div className={Styles['title']}><span>5</span>培训费用</div>
                  <div className={Styles['desc']}>
                    <p>1. 免费培训：年满16周岁、不超过65周岁未就业或灵活就业且不具有按月领取职工养老保险待遇的农民工、毕业年度及毕业2年内的高校毕业生、下岗失业人员、脱贫劳动力（含防返贫检测对象）、退捕渔民、就业援助对象（含残疾人）、未升学的应届初高中生毕业生（简称：”两后生“）</p>
                    <p>2. 收费培训：资费（不符合政府补贴的六类人员）</p>
                  </div>

                </Flex>
                <Flex vertical className={Styles['card']}>
                  <div className={Styles['title']}><span>6</span>证书颁发</div>
                  <div className={Styles['desc']}>培训结束，考试合格者，颁发医疗护理员培训（结业）证书</div>

                  <div className={Styles['title']}><span>7</span>就业范围</div>
                  <div className={Styles['desc']}>医院护理、医院产科、医养院、月子会所、家庭等服务</div>

                  <div className={Styles['title']}><span>8</span>就业和工资</div>
                  <div className={Styles['desc']}>
                    <p>1. 推荐与订单式</p>
                    <p>2. 工资：实习期4000~6000元（城市不同，实习工资不同），高工资可达1万以上</p>
                  </div>

                  <div className={Styles['title']}><span>9</span>开班时间</div>
                  <div className={Styles['desc']}>2025年01月06日</div>


                  <div className={Styles['title']}><span>10</span>报名资料</div>
                  <div className={Styles['desc']}>
                    <p>1. 身份证正反面复印件或手机拍照</p>
                    <p>2. 社保卡复印件或者手机拍照</p>
                    <p>3. 近期免冠蓝底小二寸照片6张</p>
                    <p>4. 本人手机号</p>
                  </div>


                </Flex>
              </Flex>
              <Flex vertical className={Styles['bottom']} gap={'large'}>
                <div>报名电话：0558-2703311</div>
                <div>学校地址：阜阳市颍州北路108号建银大厦电梯6楼</div>
              </Flex>
            </Flex>
        }
      </SubWrap>
    </div>
  );
}

export default Nurse;
