import React from 'react';
import { useRequest } from 'ahooks';
import Styles from './index.module.css';
import ContactPng from '../../static/contact.png';
import { Flex, Input, Space, Breadcrumb, Menu } from 'antd';
import SubWrap from '../../components/SubWrap';




function Contact() {
  useRequest(async () => {

  });




  return (
    <div>
      <SubWrap
        title={'联系我们'}
        hideContentTitle
        style={{
          background: '#f0f6ff',
          textAlign: 'left'
        }}
      >
        <Flex vertical wrap className={Styles['content']}>
          <Flex vertical>

            <h3 className={Styles['title']}>阜阳市阜大嫂职业技能培训有限公司</h3>
            <p>咨询电话：0558-2703311</p>
            <p>地址：安徽省阜阳市颍泉区中市街道颍州中路建银大厦电梯上6楼</p>
            <p>路线：市内乘1路、3路、4路、5路、9路、16路、22路、23路、25路公交车，至〖百货大楼站〗下车，建银大厦电梯上6楼</p>
          </Flex>
          <img src={ContactPng} alt="" width={500} />
        </Flex>
      </SubWrap>
    </div>
  );
}

export default Contact;
